<template>
  <div v-if="pastInquiries && pastInquiries.length > 0" class="past-inquiries">
    <h6>{{ $t("inquiry.past_inquiries") }}</h6>
    <ul
      class="list--nostyle inquiries-list"
      v-if="pastInquiries && pastInquiries.length > 0"
    >
      <li v-for="item in pastInquiries" :key="item.queryId">
        <span class="title">{{ item.queryName }}</span>
        <span class="date"
          >{{ formatDate(item.queryStartDate) }} -
          {{ formatDate(item.queryEndDate) }}</span
        >
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { parseISO, format } from "date-fns";

export default {
  name: "past-inquiries",
  computed: mapGetters("inquiries", ["pastInquiries"]),
  methods: {
    formatDate(date) {
      return format(parseISO(date), "dd.MM.yyyy");
    },
  },
};
</script>
<style lang="scss" scoped>
h6 {
  margin-bottom: 1.25rem;
}

.past-inquiries {
  margin-top: 6rem;
}

.inquiries-list {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  li {
    padding: 1rem 0.5rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    border-bottom: 1px solid #ddd;
    column-gap: 0.5em;

    &:first-child {
      border-top: 1px solid #ddd;
    }
  }
  .title {
    font-weight: 500;
  }
}
</style>
