<template>
  <div>
    <h6>{{ $t("inquiry.current_inquiries") }}</h6>
    <div
      class="my-3"
      v-if="(!inquiries || inquiries.length === 0) && !isLoading"
    >
      {{ $t("inquiry.no_inquiries") }}
    </div>
    <Loading v-if="isLoading" />
    <ul
      class="list--nostyle inquiries-list"
      v-if="inquiries && inquiries.length > 0"
    >
      <li v-for="item in currentAndUpcomingInquiries" :key="item.queryId">
        <span class="title">
          {{ item.queryName }}
          <b-badge
            v-if="isTodayBetween(item.queryStartDate, item.queryEndDate)"
            variant="primary"
            style="vertical-align: text-bottom"
            >{{ $t("admin_users.status.ACTIVE") }}</b-badge
          >
        </span>
        <span class="date"
          >{{ formatDate(item.queryStartDate) }} -
          {{ formatDate(item.queryEndDate) }}</span
        >
        <CreateInquiry
          v-if="isAfterToday(item.queryStartDate)"
          class="edit-item"
          :queryHash="item.queryHash"
          :queryId="item.queryId"
          edit
        />
        <div v-else></div>
        <button
          class="button button--link copy-link"
          @click="copyLink(item.queryHash, item.queryId)"
        >
          {{
            copied === item.queryId
              ? $t("inquiry.copied")
              : $t("inquiry.copy_link")
          }}
        </button>
        <ConfirmationModal
          v-if="isAfterToday(item.queryStartDate)"
          buttonClasses="button button--link"
          :openModalText="$t('common.delete')"
          :id="item.queryId"
          :onAccept="() => deleteItem(item.queryId)"
          :onAcceptButtonText="$t('common.delete')"
          class="delete-item"
        >
          <p class="text-center">
            {{ $t("common.are_you_sure_you_want_to_delete") }}<br />
            <strong>"{{ item.queryName }}"</strong>?
          </p>
        </ConfirmationModal>
      </li>
    </ul>
  </div>
</template>
<script>
import ConfirmationModal from "./forms/modals/ConfirmationModal.vue";
import Loading from "@/components/Loading.vue";
import { parseISO, format, isAfter, isBefore } from "date-fns";
import { mapGetters, mapState } from "vuex";
import CreateInquiry from "./forms/CreateInquiry.vue";
import { deleteQueryById } from "@/api/inquiryController";

export default {
  name: "current-inquiries",
  components: { ConfirmationModal, Loading, CreateInquiry },
  data() {
    return {
      copied: null,
    };
  },
  computed: {
    ...mapState({
      inquiries: (state) => state.inquiries.inquiries,
      isLoading: (state) => state.inquiries.isLoading,
      selectedLocale: (state) => state.selectedLocale,
      selectedCompanyId: (state) => state.companies.selectedCompanyId,
    }),
    ...mapGetters("inquiries", ["currentAndUpcomingInquiries"]),
  },
  methods: {
    isTodayBetween(startISO, endISO) {
      const today = new Date();
      return (
        isAfter(today, parseISO(startISO)) && isBefore(today, parseISO(endISO))
      );
    },
    isAfterToday(startDateISO) {
      const today = new Date();
      return isAfter(parseISO(startDateISO), today);
    },
    formatDate(date) {
      return format(parseISO(date), "dd.MM.yyyy");
    },
    async copyLink(link, id) {
      try {
        await navigator.clipboard.writeText(
          `${process.env.VUE_APP_QUERY_BASE_URL}/${link}`
        );
        this.copied = id;
      } catch {
        console.log("Cannot copy to clipboard");
      }
    },
    deleteItem(id) {
      deleteQueryById(id, this.selectedLocale)
        .then((res) => {
          if (res.status === 200) {
            this.$bvToast.toast(this.$t("toast.deleted_successfully"), {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            });
            return;
          }
          this.$bvToast.toast(this.$t("toast.something_went_wrong"), {
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            noCloseButton: true,
          });
        })
        .finally(() =>
          this.$store.dispatch(
            "inquiries/getInquiriesByCompany",
            this.selectedCompanyId
          )
        );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/style.scss";

h6 {
  margin-bottom: 1.25rem;
}
.inquiries-list {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  li {
    padding: 1rem 0.5rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    border-bottom: 1px solid #ddd;
    column-gap: 0.5em;

    &:first-child {
      border-top: 1px solid #ddd;
    }

    .delete-item,
    .copy-link,
    .edit-item {
      justify-self: end;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 2fr 2fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }
    @include media-breakpoint-down(sm) {
      .title {
        grid-column: 1;
        grid-row: 1;
      }
      .date {
        grid-column: 1;
        grid-row: 2;
        font-size: 0.85em;
      }
      .copy-link {
        grid-column: 1;
        grid-row: 3;
        text-align: left;
        justify-self: start;
      }
      .edit-item {
        grid-row: 1;
        grid-column: 2;
      }
      .delete-item {
        grid-column: 2;
        grid-row: 2;
        display: inline-block;
        width: fit-content;
        justify-self: end;
      }
    }

    .title {
      font-weight: 500;
    }
  }
}
</style>
