<template>
  <div>
    <Header :heading="$t('inquiry.inquiries')" />
    <SelectCompany :addCompany="false" />
    <Container v-if="selectedCompanyId">
      <keep-alive>
        <router-view> </router-view>
      </keep-alive>
      <CurrentInquiries />
      <CreateInquiry />
      <PastInquiries />
    </Container>
  </div>
</template>
<script>
import Container from "../components/Container.vue";
import CreateInquiry from "../components/forms/CreateInquiry.vue";
import CurrentInquiries from "../components/CurrentInquiries.vue";
import SelectCompany from "../components/forms/SelectCompany.vue";
import Header from "../components/Header.vue";
import PastInquiries from "../components/PastInquiries.vue";
import { mapState } from "vuex";

export default {
  components: {
    Header,
    SelectCompany,
    Container,
    CreateInquiry,
    CurrentInquiries,
    PastInquiries,
  },
  name: "inquiries",
  metaInfo() {
    return {
      title: this.$t("inquiry.inquiries"),
    };
  },
  computed: mapState({
    selectedCompanyId: (state) => state.companies?.selectedCompany?.id,
  }),
};
</script>
